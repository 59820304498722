import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import HTMLFlipBook from "react-pageflip";

// Set up PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`;

const PDFFlipbook = ({ pdfUrl }) => {
  const flipbookRef = useRef(null);
  const [numPages, setNumPages] = useState(null);

  return (
    <div className="flipbook-container">
      <HTMLFlipBook ref={flipbookRef} width={800} height={600}>
        <Document
          file={pdfUrl}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={index} pageNumber={index + 1} />
          ))}
        </Document>
      </HTMLFlipBook>
    </div>
  );
};

export default PDFFlipbook;
