import React, { useState } from 'react';
import Modal from 'react-modal';
import './photos.css'; // Add your own CSS styling
import NewsScroller from '../Home/Carousel';
import one from '../images/Glimses/11.png'
import one1 from '../images/Glimses/12.png'
import two from '../images/Glimses/21.png'
import two1 from '../images/Glimses/22.png'
import Three from '../images/Glimses/31.png'
import four from '../images/Glimses/41.png'
import four2 from '../images/Glimses/42.png'
import five from '../images/Glimses/51.png'
import five2 from '../images/Glimses/52.png'
import five3 from '../images/Glimses/53.png'
import six from '../images/Glimses/61.png'
import six2 from '../images/Glimses/62.png'
import seven from '../images/Glimses/71.png'
import seven2 from '../images/Glimses/72.png'



Modal.setAppElement('#root'); // For accessibility

const events = [
  {
    event: "82nd All India Annual Art Exhibition Inauguration Ceremony by Exhibition Society dignitaries Dr.B. Prabha Shankar , President , Economic Committe,Ashwin Margam,Vice-President,Exhibition Society,Sainath Dayakar Sastri,Secretary Exhibition Society",
    images: [
      one,
      one1
      
      
    ],
  },
  {
    event: "Award Ceremony - 2022 May ,Chief Guest Smt. S. Vani Devi, MLC, Mamidi Harikrishna. Director, Dept. of Language & Culture Govt. of Telangana, Aditya Margam, Exhibition Society Hyderabad, Sri K. Dasharatha Reddy",
    images: [
      two,
      two1
      
      
    ],
  },
  {
    event: "An Art Camp on the occasion of Azadi Ka Amrit Mahotsav - 2022 July ,'MERA BHARAT MAHAN' with 75 artists is organized by Salar Jung Museum Chief Guest Dr. A. Nagender Reddy, Director Salar Jung Museum.",
    images: [
      Three,
      
      
      
    ],
  },
  {
    event: "Azadi Ka Amrit Mahotsav Painting Workshop - 2022 September ,Lalit Kala Akademi New Delhi & Regional Centre, Chennai organized Azadi Ka Amrit Mahotsav Painting Workshop with 50 Artists M. V. Ramana Reddy, President of the Hyderabad Art Society Coordinated the event.",
    images: [
      four,
      four2
      
      
      
    ],
  },
  {
    event: "Lalit Kala Akademi Art Exhibition - 2022 September ,Exhibition of 50 Artworks done at the Art Workshop organized by Lalit Kala Akademi New Delhi & Regional Centre, Chennai at Parade Grounds, Secunderabad G. Kishan Reddy Minister of Tourism, Culture & Development of North Eastern Region of India and Uma Nanduri IAS, Chairman Lalit Kala Academy New Delhi were present",
    images: [
      five,
      five2,
      five3
      
      
      
    ],
  },
  {
    event: "Exhibition of Hyderabad Art Society Collection - 2022 May ,Exhibition of Hyderabad Art Society collection, Inaugurated by Ajay Mishra, IAS (Retd) ,Spl. C.S. Govt of Telangana, Prof. Dr. Jayarama Reddy, Vice Chancellor ,Sri CSV Mahavidyalaya, Kancipuram, Prof. T. Kishen Rao, Vice-Chancellor, PSTU.",
    images: [
      six,
      six2
      
      
      
    ],
  },
  {
    event: "President Felicitation - September - 2022 ,M. V. Ramana Reddy, President of the Hyderabad Art Society is honoured by members of HAS for his services.",
    images: [
      seven,
      seven2
      
      
      
    ],
  },
  // Add more events and images as needed
];

const Gallery = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState([]);

  const openModal = (images, index) => {
    setCurrentImages(images);
    setCurrentIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const showNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % currentImages.length);
  };

  const showPrevImage = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex - 1 + currentImages.length) % currentImages.length
    );
  };

  return (
    <>
    <div className="gallery">
      <h1>Glimpses</h1>
      {events.map((event, eventIndex) => (
      
        <div key={eventIndex} className="event-section">
          
          <h2>{event.event}</h2>
          <div className="event-images">
            {event.images.map((src, imageIndex) => (
              <img
                key={imageIndex}
                src={src}
                alt={`Gallery ${imageIndex}`}
                onClick={() => openModal(event.images, imageIndex)}
                className="gallery-image"
              />
            ))}
          </div>
        </div>
        
      ))}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <button className="close-button" onClick={closeModal}>Close</button>
        <button className="prev-button" onClick={showPrevImage}>Previous</button>
        <img src={currentImages[currentIndex]} alt={`Image ${currentIndex}`} className="modal-image" />
        <button className="next-button" onClick={showNextImage}>Next</button>
      </Modal>
      
    </div>
    <div className="news-section">
    <h2>Latest News</h2>
    <NewsScroller />
  </div>
  </>
  );
};

export default Gallery;
