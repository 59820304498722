import React from 'react';
import Slider from 'react-slick';
import NewsScroller from './Carousel';
import TypingSlogans from './TypingSlogans';
import logo from '../logo.jpeg';
import img1 from './awrd.jpg';
import img2 from './IMG-20240729-WA0019.jpg';
import img3 from './3.jpg';
import img4 from './4.jpg';
import img5 from './5.jpg';
import img6 from './main1.jpg';
import poster from './event.jpg'; // Event poster image

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Home.css';

const Home = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div className="home">
      <Slider {...settings} className="home-carousel">
        <div><img className="d-block w-100" src={img6} alt="Slide 1" /></div>
        <div><img className="d-block w-100" src={img2} alt="Slide 2" /></div>
        <div><img className="d-block w-100" src={img3} alt="Slide 3" /></div>
        <div><img className="d-block w-100" src={img4} alt="Slide 4" /></div>
        <div><img className="d-block w-100" src={img5} alt="Slide 5" /></div>
        <div><img className="d-block w-100" src={img1} alt="Slide 6" /></div>
      </Slider>

      {/* Event Section (Poster on Left, Info on Right) */}
      <div className="event-section">
        <div className="event-poster">
          <img src={poster} alt="Upcoming Event" />
        </div>
        <div className="event-info">
          <h3>🎨 Upcoming Event</h3>
          <p>Join us for an exciting artistic experience featuring talented artists, exhibitions, and interactive sessions.</p>
          <p>Discover new perspectives, engage with creative minds, and celebrate the beauty of art.</p>
          
        </div>
      </div>

      <div className="welcome-section">
        <h1>Welcome to Hyderabad Art Society</h1>
        <p>
          Hyderabad Art Society (HAS) was established in 1941 under the leadership of eminent personalities and has been dedicated to the promotion of art and artists for over 84 years. It is one of the oldest art organizations in India, actively organizing All India Art Exhibitions, workshops, and international collaborations.
        </p>
        <p>
          HAS has played a crucial role in nurturing emerging artists and providing them with a prestigious platform. Several renowned artists have been associated with our exhibitions and programs, gaining recognition nationally and internationally.
        </p>
        <p>
          Our society has collaborated with esteemed institutions such as Lalit Kala Akademi, Salar Jung Museum, JNAFAU, and many others to promote artistic endeavors and cultural awareness.
        </p>
        <p>
          Over the years, HAS has organized numerous significant events, including art camps, exhibitions, and retrospectives. Our Platinum Jubilee celebrations in 2016 were a landmark event that showcased the rich artistic heritage of Hyderabad and beyond.
        </p>

        <div className="doctors-box">
          <div className="doctors-content">
            <h2>Our Recent Events</h2>
            <table>
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Event</th>
                </tr>
              </thead>
              <tbody>
                <tr><td>2020</td><td>International Women Artists Camp & Exhibition</td></tr>
                <tr><td>2019</td><td>Batukamma Art Camp</td></tr>
                <tr><td>2018</td><td>Dr. Krishna Reddy Retrospective</td></tr>
                <tr><td>2017</td><td>World Telugu Conference Art Camp & Exhibition</td></tr>
                <tr><td>2016</td><td>Platinum Jubilee Celebrations & National Art Camp</td></tr>
              </tbody>
            </table>
          </div>
          <img className="art-logo" src={logo} alt="HAS Logo" />
        </div>
      </div>
      <div className="news-section">
        <h2>Latest Updates</h2>
        <NewsScroller />
      </div>
    </div>
  );
};

export default Home;
