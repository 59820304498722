import React from 'react';
import hasImage from '../images/temp/haslogo.png';  // Update the path accordingly
import './about-founder.css';
import NewsScroller from '../Home/Carousel';


const AboutFounder = () => {
    return (
        <div className="about-founder">
            <h2>About Hyderabad Art Society</h2>
            <div className="founder-content">
                <div className="founder-text">
                    <p>
                        Hyderabad Art Society (HAS) was established in 1941 under the leadership of eminent persons like Mehdi Nawaj Jung, 
                        Nawab Salarjung Bahadur, and Khan Bahadur Syed Ahmed Yazdani. It has significantly contributed to the promotion of art in India. 
                        Over the years, HAS has organized several national and international exhibitions in collaboration with Central Lalit Kala Akademi, 
                        AIFACS, and All India Industrial Exhibition Society.
                    </p>
                    <p>
                        HAS has been organizing All India Art Exhibitions for the past 84 years, providing opportunities to young and talented artists. 
                        It is dedicated to creating awareness and promoting art in society while supporting upcoming artists and students. 
                        Several renowned artists have participated in HAS events, including V. S. Gaitonde, M. F. Hussain, Ram Kinker Baij, 
                        Thota Vaikuntam, Laxma Goud, and many more.
                    </p>
                    <p>
                        The Society has played a crucial role in shaping the careers of many eminent artists. Over the decades, HAS has organized 
                        numerous events such as international art camps, retrospective exhibitions, and themed exhibitions celebrating Telugu culture.
                    </p>
                </div>
                <div className="founder-image">
                    <img src={hasImage} alt="Hyderabad Art Society" />
                </div>
            </div>
            <h3>Milestones & Celebrations</h3>
            <ul>
                <li>Silver Jubilee in 1966 under the presidentship of L. N. Gupta</li>
                <li>Golden Jubilee in 1991-1992 under the leadership of P. T. Reddy</li>
                <li>Platinum Jubilee in 2016 with grand celebrations led by M. V. Ramana Reddy</li>
                <li>Numerous exhibitions, art camps, and collaborations with cultural institutions</li>
            </ul>
            <h3>Recent Events</h3>
            <ul>
                <li>2020 - International Women Artists Camp, Art Exhibition at ICCR & Regional Passport Office Hyderabad</li>
                <li>2019 - Batukamma Art Camp</li>
                <li>2018 - Dr. Krishna Reddy Retrospective</li>
                <li>2017 - Symphony of Colors with 210 artists, World Telugu Conference Art Camp</li>
                <li>2016 - Weeklong Platinum Jubilee Celebrations, National Bathukamma Painting Competition</li>
            </ul>
            <p>
                The Hyderabad Art Society continues to play a vital role in promoting art and nurturing talent. 
                With the support of institutions like Lalit Kala Akademi, ICCR, Salar Jung Museum, and JNAFAU, 
                HAS is dedicated to enriching the cultural landscape of Hyderabad and beyond.
            </p>
          
            <div className="news-section">
                <h2>Latest News</h2>
                <NewsScroller />
            </div>
        </div>
    );
};

export default AboutFounder;
