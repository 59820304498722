import React from 'react';
import './Services.css';
import NewsScroller from '../Home/Carousel';
import rams from '../images/HAS_mgmt/Ramanareddy.png'
import ven from '../images/HAS_mgmt/venkateswarulu.png'
import anan from '../images/HAS_mgmt/anantaiah.png'
import aji from '../images/HAS_mgmt/ajitha.png'
import kan from '../images/HAS_mgmt/kantha reddy.png'
import malle from '../images/HAS_mgmt/mallesham.png'
import raje from '../images/HAS_mgmt/Rajendar.png'
import rames from '../images/HAS_mgmt/ramesh.png'
import bala from '../images/HAS_mgmt/bala.png'
import balarj from '../images/HAS_mgmt/mbalaraj.png'
import narsimlu from '../images/HAS_mgmt/narsimlu.png'
import kishan from '../images/HAS_mgmt/kishan.png'
import sampat from '../images/HAS_mgmt/sampat.png'
import narender from '../images/HAS_mgmt/Narender.png'
import amila from '../images/HAS_mgmt/amila.png'
import parj from '../images/HAS_mgmt/prajender.png'
import anand from '../images/HAS_mgmt/Tujanand.png'
import ranga from '../images/HAS_mgmt/Ranga.png'


const servicesData = [
    {
        title: 'M V RAMANA REDDY',
        description: 'President',
        imageUrl:rams,
    },
    {
        title: 'Jageti Venkateshwarlu',
        description: 'Hon.Secretary',
        imageUrl: ven
    },
    {
        title: 'Anantaiah',
        description: 'Vice President',
        imageUrl: anan
    },
    {
        title: 'Ajitha Surabhi',
        description: 'Vice President',
        imageUrl:aji
    },
    {
        title: 'S Kantha Reddy',
        description: 'Hon.Joint Secretary',
        imageUrl: kan
    },
    {
        title: 'Gurram Mallesham',
        description: 'Hon.Joint Secretary',
        imageUrl: malle
    },
    {
        title: "Rajender Nagula",
        description: 'Hon.Treasurer',
        imageUrl: raje
    },
    {
        title: 'Gangji Ramesh Kumar',
        description: 'Executive Member',
        imageUrl: rames
    },
    {
        title: 'Bala Bhaktha Raju',
        description: 'Executive Member',
        imageUrl: bala
    },
    {
        title: 'M Balraj',
        description: 'Executive Member',
        imageUrl: balarj
    },
    {
        title: 'Kandi Narsimlu',
        description: 'Executive Member',
        imageUrl: narsimlu
    },
    {
        title: 'Kishan Kappari',
        description: 'Executive Member',
        imageUrl:kishan
    },
    {
        title: 'A.Sampat Reddy',
        description: 'Executive Member',
        imageUrl: sampat
    },
    {
        title: 'Narender Annavaram',
        description: 'Executive Member',
        imageUrl:narender
    },
    {
        title: 'Amila Reddy Nalla',
        description: 'Executive Member',
        imageUrl: amila
    },
    {
        title: 'Rajender Kumar P',
        description: 'Executive Member',
        imageUrl: parj
    },
    {
        title: 'Tuljanand',
        description: 'Executive Member',
        imageUrl: anand
    },
    {
        title: 'G.Ranga Reddy',
        description: 'Executive Member',
        imageUrl:ranga
    }
];

const Services = () => {
    return (
        <>
        <div className="services-container">
            <h1>Our Management</h1>
            <div className="services-list">
                {servicesData.map((service, index) => (
                    <div key={index} className="service-item">
                        <img src={service.imageUrl} alt={service.title} className="service-image" />
                        <div className="service-content">
                            <h2>{service.title}</h2>
                            <p>{service.description}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="news-section">
         <h2>Latest News</h2>
         <NewsScroller />
       </div>
        </div>
        
       </>
    );
}

export default Services;
