// src/components/Footer.js
import React from 'react';
import './Footer.css'; // Import CSS for styling
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer-container">
      <footer className="footer">
        <div className="footer-section">
          <h2>Address</h2>
          <p>Hyderabad Art Society</p>
          <p>Exhibition Grounds, Nampally, Hyderabad, Telangana 500001</p>
          <p>India</p>
          <p>Phone: +91 9866106270</p>
          <p>Email: contact@hyderabadartsociety.com</p>
        </div>

        <div className="footer-section">
          <h2>About Us</h2>
          <p>Hyderabad Art Society is dedicated to promoting and preserving art and culture.</p>
        </div>

        <div className="footer-section">
          <h2>Quick Links</h2>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about_founder">About Us</Link></li>
            <li><Link to="/gallery">Glimpses</Link></li>
            <li><Link to="/contact_us">Contact Us</Link></li>
          </ul>
        </div>
      </footer>
      <div className="footer-bottom">
        <p>
          © {new Date().getFullYear()} Hyderabad Art Society. All rights reserved. | Designed and Developed by
          <a href="https://vedantsolutions.com" target="_blank" rel="noopener noreferrer"> Vedant Solutions</a>
        </p>
      </div>

    </div>
  );
};

export default Footer;
