import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home/Home';
import AboutFounder from './components/about_us/about_founder';
import SuccessStories from './components/about_us/Succes_story';
import VIPsOpinions from './components/about_us/vip_opinion';
import AdvisorsCommunity from './components/about_us/AdvisorsCommunity';
import Services from './components/Services/Services';

import PDFFlipbook from './components/MediaRoom/book';
import Gallery from './components/gallery/photos';

import ContactUs from './components/ContactUs/ContactUs';
import LoadingSpinner from './components/loader'; // Import the LoadingSpinner component
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions';
import './global.css';
import ScrollToTop from './components/ScrollToTop';
function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a data fetch or other async operations
    setTimeout(() => setLoading(false), 2000); // Example: 2 seconds loading time
  }, []);

  return (
    <Router>
      {loading ? (
        <LoadingSpinner /> // Display the spinner while loading
      ) : (
        <>
         <ScrollToTop />
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about_founder" element={<AboutFounder />} />
            <Route path="/success_stories" element={<SuccessStories />} />
            <Route path="/vip_opinion" element={<VIPsOpinions />} />
            <Route path="/advisors_community" element={<AdvisorsCommunity />} />
            <Route path="/services" element={<Services />} />
          
            <Route path="/book" element={<PDFFlipbook />} />
            
            <Route path="/gallery" element={<Gallery />} />
           
            <Route path="/contact_us" element={<ContactUs />} />
            <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route path="/terms_conditions" element={<TermsConditions />} />
          </Routes>
          <Footer />
        </>
      )}
    </Router>
  );
}

export default App;
